 <template>
  <div class="p-grid">
    <div class="p-col-12">
      <Panel header="Gestão de Usuários">
        <Toolbar class="p-mb-3">
          <template v-slot:left>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success"
              @click="showCreateUser()"
            />
          </template>
          <template v-slot:right>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help p-mb-2"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
        <DataTable
          ref="dt"
          :value="usuarios"
          class="p-datatable-sm"
          v-model:selection="selectedUsuario"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
          :filters="filters"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} usuários"
        >
          <template #header>
            <div
              class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              "
            >
              <h5>Gestão de Usuário</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Pesquisar..."
                />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column header="Usuário" field="nome" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Usuário:</span>
              {{ slotProps.data.nome }}
            </template>
          </Column>
          <Column header="E-mail" field="email" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">E-mail:</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column header="Status" field="status" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Status:</span>
              <span
                v-if="slotProps.data.status === true"
                :class="'product-badge status-' + slotProps.data.status"
                @click="updateStatusUser(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA INATIVAR'"
                style="cursor: pointer"
                >Ativo</span
              >
              <span
                v-if="slotProps.data.status === false"
                :class="'product-badge status-' + slotProps.data.status"
                @click="updateStatusUser(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA ATIVAR'"
                style="cursor: pointer"
                >Inativo</span
              >
              <!--{{ slotProps.data.status }}-->
            </template>
          </Column>
          <Column header="Setor" field="setor.sigla" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Setor:</span>
              <b>{{ slotProps.data.setor.sigla }}</b>
            </template>
          </Column>
          <Column header="Grupos/Permissões" field="grupo.descricao">
            <template #body="slotProps">
              <span class="p-column-title">Grupos/Permissões:</span>
              <Button
                @click="show(slotProps.data.grupos.permissoes)"
                :label="slotProps.data.grupos.descricao"
                class="p-button-rounded p-button-secondary"
              ></Button>
              <OverlayPanel
                ref="op"
                appendTo="body"
                :showCloseIcon="true"
                id="overlay_panel"
                style="width: 350px"
                :breakpoints="{ '960px': '75vw' }"
              >
                <ul>
                  <li v-for="permissao in permissao" :key="permissao.id">
                    {{ permissao.descricao.replace("ROLE_", "") }}.
                  </li>
                </ul>
              </OverlayPanel>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <span class="p-column-title">Ações:</span>
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="showUpdateUser(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EDITAR'"
              />
              <Button
                icon="pi pi-lock"
                class="p-button-rounded p-button-info p-mr-2"
                @click="showResetePasswordUser(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA RESETAR SENHA'"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning"
                @click="showDeleteUser(slotProps.data)"
                v-tooltip.top="'CLIQUE PARA EXCLUIR'"
              />
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>

  <!--MODAL CADASTRAR-->
  <Dialog
    v-model:visible="usuarioDialog"
    header="Cadastro de usuário"
    :style="{ width: '800px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="p-fluid" style="padding: 30px">
      <div class="p-text-bold"><h5>Usuários e Permissões</h5></div>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="nome">Nome</label>
          <InputText
            id="nome"
            v-model="v$.usuario.nome.$model"
            placeholder="Digite seu nome e sobrenome."
            autofocus
            :class="{ 'p-invalid': submitted && v$.usuario.nome.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.usuario.nome.$invalid"
            >Nome é obrigatório. Min.3 e Max.50.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="email">E-mail</label>
          <InputText
            id="email"
            v-model="v$.usuario.email.$model"
            placeholder="Digite seu e-mail."
            :class="{ 'p-invalid': submitted && v$.usuario.email.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.usuario.email.$invalid"
            >Email é obrigatório. Informe um e-mail válido.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid" v-if="!usuario.id">
        <div class="p-field p-col-12 p-md-6">
          <label for="senha">Senha</label>
          <Password
            id="senha"
            v-model="v$.usuario.senha.$model"
            placeholder="Informe uma senha."
            toggleMask
            :class="{ 'p-invalid': submitted && v$.usuario.senha.$invalid }"
          >
            <template #footer="sp">
              {{ sp.level }}
              <br />
              <p class="mt-2">Sugestões:</p>
              <ul class="pl-2 ml-2 mt-0" style="line-height: 1.2">
                <li>Pelo menos uma minúscula.</li>
                <li>Pelo menos uma maiúscula.</li>
                <li>Pelo menos um numérico.</li>
                <li>Mínimo de 6 caracteres.</li>
              </ul>
            </template>
          </Password>
          <small class="p-invalid" v-if="submitted && v$.usuario.senha.$invalid"
            >Senha é obrigatório.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="confSenha">Confirmar Senha</label>
          <Password
            id="confSenha"
            v-model="v$.confSenha.$model"
            placeholder="Confirme sua senha."
            toggleMask
            :feedback="false"
            :class="{ 'p-invalid': submitted && v$.confSenha.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.confSenha.$invalid"
            >Confirmar senha deve ser igual a senha informada.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="tipo">Contato</label>
          <InputMask
            id="contato"
            v-model="v$.usuario.contato.$model"
            placeholder="(99) 9 9999-9999"
            mask="(99) 9 9999-9999"
            :class="{
              'p-invalid': submitted && v$.usuario.contato.$invalid,
            }"
          />
          <small
            class="p-invalid"
            v-if="submitted && v$.usuario.contato.$invalid"
            >Contato é obrigatório.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6">
          <label for="tipo">Grupo de Permissões</label>
          <Dropdown
            id="tipo"
            placeholder="Selecione um grupo"
            v-model="v$.selectedGroup.$model"
            optionLabel="descricao"
            @change="changerPermissionsForGroup()"
            :options="groups"
            :class="{ 'p-invalid': submitted && v$.selectedGroup.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.selectedGroup.$invalid"
            >Grupo de permissões é obrigatório.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
          <label for="setor">Setor</label>
          <Dropdown
            id="setor"
            placeholder="Selecione um setor"
            v-model="v$.selectedSetor.$model"
            optionLabel="sigla"
            :options="setores"
            :class="{ 'p-invalid': submitted && v$.selectedSetor.$invalid }"
          />
          <small class="p-invalid" v-if="submitted && v$.selectedSetor.$invalid"
            >Setor é obrigatório.</small
          >
        </div>
      </div>

      <div class="p-fluid p-formgrid p-grid">
        <div class="p-text-bold">
          <h5>Lista de Permissões</h5>
        </div>
        <div class="p-col-12" style="padding: 5px">
          <Listbox
            v-model="usuario.grupos.permissoes"
            :options="permissoes"
            :multiple="true"
            :filter="true"
            disabled
            optionLabel="descricao"
            listStyle="max-height:250px"
            filterPlaceholder="DIGITE O NOME DA PERMISSÃO."
            emptyFilterMessage="Nenhum Resultado..."
            emptyMessage="Nenhum Resultado..."
          >
            <template #header> </template>
            <template #option="slotProps">
              <div class="product-list-detail">
                <i class="pi pi-unlock product-category-icon p-mr-3"> </i>
                <span class="product-category">
                  {{ slotProps.option.descricao.replace("ROLE_", "") }}.</span
                >
              </div>
            </template>
          </Listbox>
        </div>
        <!--  CADASTRO DE USUARIO -->
      </div>
    </div>
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-raised"
        icon="pi pi-check"
        @click="createOrUpdateUser(!v$.usuario.$invalid)"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL CADASTRAR-->

  <!--MODAL RESETAR SENHA-->
  <Dialog
    v-model:visible="usuarioReseteDialog"
    header="Deseja resetar a senha?"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="usuario"
        >Tem certeza de que deseja resetar a senha do usuario
        <b>{{ usuario.nome }} ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="resetePasswordUser()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL RESETAR SENHA-->

  <!--MODAL DELETAR-->
  <Dialog
    v-model:visible="usuarioDeleteDialog"
    header="Deseja excluir este usuário?"
    :style="{ width: '450px' }"
    :modal="true"
    @hide="hideDialogs()"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="usuario"
        >Tem certeza de que deseja excluir do sistema o usuario
        <b>{{ usuario.nome }} ?</b>
      </span>
    </div>
    <template #footer>
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-raised"
        @click="deleteUser()"
      />
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-raised p-button-text"
        @click="hideDialogs()"
      />
    </template>
  </Dialog>
  <!--FIM MODAL DELETAR-->
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import {
  required,
  minLength,
  maxLength,
  sameAs,
  email,
} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import AuthService from "@/service/Auth/AuthService";
import UsersService from "@/service/Users/UsersService";
import GroupService from "@/service/Grupos/GroupService";
import PermissionService from "@/service/Permissoes/PermissionService";
import SetorService from "@/service/Setor/SetorService";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      usuarios: null,
      usuario: {
        id: null,
        nome: null,
        contato: null,
        email: null,
        senha: null,
        status: true,
        grupos: {
          id: null,
          descricao: null,
          permissoes: null,
        },
        setor: {
          id: null,
          nome: null,
          sigla: null,
        },
      },
      confSenha: null,
      setores: null,
      selectedSetor: null,
      groups: null,
      selectedGroup: null,
      usuarioDialog: false,
      usuarioUpdateDialog: false,
      usuarioDeleteDialog: false,
      usuarioReseteDialog: false,
      submitted: null,
      filters: {},
      selectedUsuario: null,
      permissoes: null,
    };
  },
  validations() {
    return {
      usuario: {
        nome: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(255),
        },
        contato: {
          required,
          maxLength: maxLength(16),
        },
        email: {
          required,
          email,
        },
        senha: {
          required,
          minLength: minLength(6),
        },
      },
      confSenha: {
        required,
        sameAs: sameAs(this.usuario.senha),
      },
      selectedGroup: {
        required,
      },
      selectedSetor: {
        required,
      },
    };
  },
  authService: null,
  usersService: null,
  groupService: null,
  permissionService: null,
  setorService: null,
  created() {
    this.authService = new AuthService();
    this.usersService = new UsersService();
    this.groupService = new GroupService();
    this.permissionService = new PermissionService();
    this.setorService = new SetorService();
    this.initFilters();
  },
  mounted() {
    this.findAllUsers();
    this.findAllPermissions();
    this.findAllGroups();
    this.findAllSetors();
  },
  methods: {
    showCreateUser() {
      this.usuarioDialog = true;
    },
    showUpdateUser(usuario) {
      this.usuario = usuario;
      this.selectedGroup = this.usuario.grupos;
      this.selectedSetor = this.usuario.setor;
      this.usuario.senha = "123456";
      this.confSenha = "123456";
      this.usuarioDialog = true;
    },
    showDeleteUser(usuario) {
      this.usuario = usuario;
      this.usuarioDeleteDialog = true;
    },
    showResetePasswordUser(usuario) {
      this.usuario = usuario;
      this.usuarioReseteDialog = true;
    },
    hideDialogs() {
      this.submitted = false;
      this.confSenha = null;
      this.selectedGroup = null;
      this.selectedSetor = null;
      this.usuario = {
        id: null,
        nome: null,
        contato: null,
        email: null,
        senha: null,
        status: true,
        grupos: {
          id: null,
          descricao: null,
          permissoes: [],
        },
        setor: {
          id: null,
          nome: null,
          sigla: null,
        },
      };
      this.usuarioDialog = false;
      this.usuarioUpdateDialog = false;
      this.usuarioDeleteDialog = false;
      this.usuarioReseteDialog = false;
    },
    findAllUsers() {
      this.usersService
        .findAll()
        .then((data) => {
          this.usuarios = data.content;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findAllGroups() {
      this.groupService
        .findAll()
        .then((data) => {
          this.groups = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findAllPermissions() {
      this.permissionService
        .findAll()
        .then((data) => {
          this.permissoes = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    findAllSetors() {
      this.setorService.findAll().then((data) => {
        this.setores = data;
      });
    },
    createOrUpdateUser(isFormValid) {
      this.submitted = true;
      if (this.usuario.id === null) {
        if (!isFormValid) {
          return;
        }
        this.usuario.setor = this.selectedSetor;
        this.usersService
          .create(this.usuario)
          .then((data) => {
            this.$msgSuccess(data);
            this.findAllUsers();
            this.hideDialogs();
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      } else {
        this.usuario.senha = "123456";
        this.confSenha = "123456";
        this.usuario.setor = this.selectedSetor;
        
        if (!isFormValid) {
          return;
        }
        this.usersService
          .update(this.usuario.id, this.usuario)
          .then((data) => {
            if (data.status === 200) {
              this.$msgSuccess(data);
              this.findAllUsers();
              this.hideDialogs();
            }
          })
          .catch((error) => {
            this.$msgErro(error);
          });
      }
    },
    updateStatusUser(usuario) {
      this.usuario = usuario;
      if (this.usuario.status === true) {
        this.usersService
          .updateStatus(this.usuario.id, "false")
          .then(() => {
            this.findAllUsers();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            } else {
              this.$msgErro(error);
            }
          });
      } else {
        this.usersService
          .updateStatus(this.usuario.id, "true")
          .then(() => {
            this.findAllUsers();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$msgWarn(null);
            } else {
              this.$msgErro(error);
            }
          });
      }
    },
    resetePasswordUser() {
      this.usersService
        .resetePassword(this.usuario.id)
        .then((data) => {
          if (data.status === 204) {
            this.$msgSuccess(data);
            this.hideDialogs();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          } else {
            this.$msgErro(error);
          }
        });
    },
    deleteUser() {
      this.usersService
        .delete(this.usuario.id)
        .then((data) => {
          if (data.status === 204) {
            this.$msgSuccess(data);
            this.findAllUsers();
            this.hideDialogs();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          } else {
            this.$msgErro(error);
          }
        });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    changerPermissionsForGroup() {
      this.usuario.grupos = this.selectedGroup;
    },
    show(data) {
      this.$refs.op.toggle(event);
      this.permissao = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  justify-content: space-between;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-invalid {
  color: red;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  &.status-Administrador {
    background: #feedaf;
    color: #8a5340;
  }
  &.status-Operador {
    background: #c0c0c0;
    color: #000000;
  }
  &.status-Demonstracao {
    background: #b3e5fc;
    color: #23547b;
  }
  &.status-Consultor {
    background: #eccfff;
    color: #694382;
  }
  &.status-true {
    background: #c8e6c9;
    color: #256029;
  }
  &.status-false {
    background: #ffcdd2;
    color: #c63737;
  }
}
.p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
  display: none !important;
}
@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-sm {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }
        &:last-child {
          border-bottom: 1px solid var(--surface-d);
          text-align: right;
        }
        .p-rating {
          display: inline-block;
        }
      }
    }
  }
}
</style>
